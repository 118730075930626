import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Timeline from 'components/common/Timeline';
import Container from 'components/common/Container';
import TitleSection from 'components/common/TitleSection';
import FormatHtml from 'components/common/FormatHtml';
import { SectionTitle } from 'helpers/definitions';
import * as Styled from './styles';

interface Experience {
  node: {
    id: string;
    html: React.ReactNode;
    frontmatter: {
      company: string;
      position: string;
      startDate: string;
      endDate: string;
    };
  };
}

const Experience: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "experiences section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "experiences" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              company
              position
              startDate
              endDate
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = markdownRemark.frontmatter;
  const experiences: Experience[] = allMarkdownRemark.edges;

  return (
    <Styled.Wrapper>
      <Container section>
        <TitleSection
          title={sectionTitle.title}
          subtitle={sectionTitle.subtitle}
          center
        />

        {experiences.map(item => {
          const {
            id,
            html,
            frontmatter: { company, position, startDate, endDate },
          } = item.node;

          return (
            <Timeline
              key={id}
              title={company}
              subtitle={position}
              content={<FormatHtml content={html} />}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}
      </Container>
    </Styled.Wrapper>
  );
};

export default Experience;
